/*
 * Skin: Red
 * ---------
 */
.skin-red .main-header .navbar {
  background-color: #a31c36;
}
.skin-red .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-red .main-header .navbar .nav > li > a:hover,
.skin-red .main-header .navbar .nav > li > a:active,
.skin-red .main-header .navbar .nav > li > a:focus,
.skin-red .main-header .navbar .nav .open > a,
.skin-red .main-header .navbar .nav .open > a:hover,
.skin-red .main-header .navbar .nav .open > a:focus,
.skin-red .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-red .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-red .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-red .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-red .main-header .navbar .sidebar-toggle:hover {
      background: rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .skin-red .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-red .main-header .navbar .dropdown-menu li a {
    color: #fff;
  }
  .skin-red .main-header .navbar .dropdown-menu li a:hover {
    background: #a31c36;
  }
}
.skin-red .main-header .logo {
  background-color: #a31c36;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}

.skin-red .main-header .logo img {
  transition:all 0.3s ease;
}
.skin-red .main-header .logo:hover img {
  transform: scale(1.5);
}
.skin-red .main-header li.user-header {
  background-color: #a31c36;
}
.skin-red .content-header {
  background: transparent;
}
.skin-red .wrapper,
.skin-red .main-sidebar,
.skin-red .left-side {
  background-color: #222d32;
}
.skin-red .user-panel > .info,
.skin-red .user-panel > .info > a {
  color: #fff;
}
.skin-red .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226;
}
.skin-red .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.skin-red .sidebar-menu > li:hover > a,
.skin-red .sidebar-menu > li.active > a {
  color: #ffffff;
  background: #1e282c;
  border-left-color: #a31c36;
  font-weight: bold;
}
.skin-red .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41;
}
.skin-red .sidebar a {
  color: #d5dee2;
}
.skin-red .sidebar a:hover {
  text-decoration: none;
  
    font-weight: bold;
}
.skin-red .treeview-menu > li > a {
  color: #d5dee2;
}
.skin-red .treeview-menu > li.active > a,
.skin-red .treeview-menu > li > a:hover {
  color: #ffffff;
  
    font-weight: bold;
}
.skin-red .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px;
}
.skin-red .sidebar-form input[type="text"],
.skin-red .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
}
.skin-red .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-red .sidebar-form input[type="text"]:focus,
.skin-red .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-red .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-red .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}

.skin-red .bg-red-lastria {
    color: #fff;
    background-color: #a31c36 !important;
}